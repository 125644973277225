$(document).ready(function(){
    $("#testimonial-slider").owlCarousel({
        stagePadding: 0,
        loop:false,
        margin:30,
        nav:false,
        dots:true,
        autoplay:true,
        responsive:{
            0:{
                items:1,
                stagePadding: 0,
            },
            600:{
                items:1,
                stagePadding: 0,
            },
            1000:{
                items:1
            }
        }
    });
});
$(document).ready(function(){
    $("#team-slider").owlCarousel({
        stagePadding: 0,
        loop:false,
        margin:20,        
        dots:false,
        autoplay:true,
        responsive:{
            0:{
                items:1,                
                nav:true,
            },
            600:{
                items:2, 
                nav:true,               
            },
            1000:{
                items:3
            }
        }
    });
});
$(document).ready(function(){
    $("#group-slider").owlCarousel({
    stagePadding: 0,
    loop:false,
    margin:30,
    nav:false,
    dots:true,
    autoplay:true,
    responsive:{
        0:{
            items:1,
            stagePadding: 0,
        },
        600:{
            items:3,
            stagePadding: 0,
        },
        1000:{
            items:3
        }
    }
    });
});
$(document).ready(function(){
    $(window).scroll(function(){
        var sticky = $('.main-wrapper'),
            scroll = $(window).scrollTop();
    
        if (scroll >= 100) sticky.addClass('fixed-header');
        else sticky.removeClass('fixed-header');
    });
});    

// When your page loads
$(document).ready(function(){
// When the toggle areas in your navbar are clicked, toggle them
$("#search-button, #search-icon").click(function(e){
    e.preventDefault();
    $("#search-button, #search-form").toggle();
});
})